import axios from "axios";
import { Dispatch } from "redux";
import config from "../config";
import { IPage } from "../types/PageTypes";
import { ActionTypes } from "../types/ActionTypes";


export const setCurrentPage = (page: IPage | undefined = undefined) => async (dispatch: Dispatch) => {
  dispatch({
      type: ActionTypes.SET_PAGE,
      payload: page,
    });
};


export const getPage = (slug: string) => async (dispatch: Dispatch) => {
  try {
    setLoading();
    const res: any = await axios({
      method: "post",
      url: config.ROOT_URL,
      data: {
        query: `{
          page(where: {slug: "${slug}"}) {
            id,
            title,
            showInMainNav,
            slug,
            pageType,
            category,
            content {
              html
            }
          }
        }`,
      },
    });

    dispatch({
      type: ActionTypes.GET_PAGE,
      payload: res.data.data.page as IPage,
    });
  } catch (e: any) {
    dispatch({
      type: ActionTypes.PAGE_ERROR,
      payload: e.response,
    });
  }
};

export const getPages = () => async (dispatch: Dispatch) => {
  try {
    setLoading();
    const res: any = await axios({
      method: "post",
      url: config.ROOT_URL,
      data: {
        query: `{
         pages(stage: PUBLISHED) {
            id,
            title,
            showInMainNav,
            slug,
            pageType,
            category,
            content {
              html
            }
          }
      }`,
      },
    });
    dispatch({
      type: ActionTypes.GET_PAGES,
      payload: res.data.data.pages as IPage[],
    });
  } catch (e: any) {
    dispatch({
      type: ActionTypes.PAGE_ERROR,
      payload: e.response,
    });
  }
};

export const setLoading = () => {
  return {
    type: ActionTypes.SET_LOADING,
  };
};

export enum ActionTypes {
   GET_PAGES = 'GET_PAGES',
   GET_PAGE = 'GET_PAGE',
   SET_PAGE = 'SET_PAGE',
   PAGE_ERROR = 'PAGE_ERROR',
   GET_POSTS = 'GET_POSTS',
   GET_POST = 'GET_POST',
   GET_POST_PAGE_COUNT = 'GET_POST_PAGE_COUNT',
   POST_ERROR = 'POST_ERROR',
   SET_LOADING = 'SET_LOADING',
}
import { ActionTypes } from "../types/ActionTypes";
import { IPost, IPostAction, IPostsState } from "../types/PostTypes";

const initialState: IPostsState = {
  posts: [],
  post: undefined,
  postCount: 0,
  pageSize: 3,
  loading: false,
};

const postReducer = (
  state: IPostsState = initialState,
  action: IPostAction
) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_POSTS:
      return {
        ...state,
        posts: action.payload as IPost[],
        loading: false,
        error: undefined,
      };
      case ActionTypes.GET_POST_PAGE_COUNT:
        return {
          ...state,
          postCount: action.payload as number,
        };
    case ActionTypes.GET_POST:
      return {
        ...state,
        post: action.payload as IPost,
        loading: false,
        error: undefined,
      };
    case ActionTypes.POST_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default postReducer;

import { ActionTypes } from "../types/ActionTypes";
import { IPage, IPageAction, IPagesState } from "../types/PageTypes";

const initialState: IPagesState = {
   pages: [],
   loading: false,
}

const pageReducer = (state: IPagesState = initialState, action: IPageAction) => {
   switch(action.type) {
      case ActionTypes.SET_LOADING:
         return {
            ...state,
            loading: true,
         }
      case ActionTypes.GET_PAGE:
      case ActionTypes.SET_PAGE:
         return {
            ...state,
            current: action.payload as IPage,
            loading: false,
            error: undefined,
         };
      case ActionTypes.GET_PAGES:
         return {
            ...state,
            pages: action.payload as IPage[],
            loading: false,
            error: undefined,
         };
      case ActionTypes.PAGE_ERROR:
         console.error(action.payload);
         return {
            ...state,
            error: action.payload,
         };
      default:
         return state;
   }
}

export default pageReducer;
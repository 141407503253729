import { IBaseAction, IBaseState } from "./BaseTypes";

export interface IPage {
   id: string;
   title: string;
   showInMainNav: boolean;
   slug: string;
   pageType: PageType;
   category: string;
   content: {
      html: string;
   }
}

export interface IPagesState extends IBaseState {
   pages: IPage[];
   current?: IPage;
}

export interface IPageAction extends IBaseAction {
   payload: IPage[] | IPage | string,
}

export enum PageType {
   HOME = 'HOME',
   SINGLE = 'SINGLE',
   POST = 'POST',
   CATEGORY = 'CATEGORY',
}
import { CombinedState, combineReducers, Reducer } from "redux";
import pageReducer from "./pageReducer";
import postReducer from "./postReducer";
import { IPageAction } from "../types/PageTypes";
import { IPostAction } from "../types/PostTypes";

type T = Reducer<CombinedState<{ pages: never; posts: never; }>, IPageAction | IPostAction >;

const rootReducer:T = combineReducers({
   pages: pageReducer,
   posts: postReducer,
});

export default rootReducer;
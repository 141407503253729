import React from 'react'
import { Link } from 'react-router-dom';
import { IPage } from '../../types/PageTypes';

interface props {
   active: string,
   pages?: IPage[],
}

const  MainNav = ({ active, pages }: props) =>  {
   const isActive = (a:string):string => (
      a === active ? 'is-active' : ''
   );

   return (
      <div>
         <div className="main-nav">
            <aside className="menu">
               <p className="menu-label">
                  <Link to="/" className="main-nav-logo">
                     <img src='/leaf-logo-circle-opt.svg' alt='Leaf Logo' />
                  </Link>
               </p>
               <ul className="menu-list">
               <li>
                  <Link to='/' className={`navbar-item ${isActive('home')}`}>Home</Link>
               </li>
               { pages && pages.map((p) => (
                  (p.showInMainNav &&
                     <li key={p.slug}>
                        <Link
                           to={`/${p.slug}`}
                           className={`navbar-item ${isActive(p.slug)}`}
                           >
                              {p.title}
                        </Link>
                     </li>
                  )
               ))}
               </ul>
            </aside>
         </div>
      </div>
   )
}

export default MainNav;

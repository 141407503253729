import React, { Fragment, ReactElement, useEffect } from "react";
import { connect } from "react-redux";

import { IPage, PageType } from "../../types/PageTypes";
import { setCurrentPage, getPage, getPages } from "../../actions/pageActions";
import Post from "./Post";
import CategoryIndex from "./CategoryIndex";
import IApplicationState from "../../types/ApplicationState";
import MainNav from "../layout/MainNav";

interface IProps {
  slug: string;
  pageType?: PageType;
  page?: IPage;
  pages?: IPage[];
  pageNumber?: number;
  setCurrentPage?: (page: IPage | undefined | void) => void;
  getPage?: (slug: string) => void;
  getPages?: () => void;
}

const Page = ({ slug, pageType, page, pages, pageNumber, setCurrentPage, getPage, getPages }: IProps) => {
  useEffect(() => {
    getPages && getPages();
    if(pageType !== PageType.POST && pageType !== PageType.HOME) {
      getPage && getPage(slug);
    } else {
      setCurrentPage && setCurrentPage();
    }
    // eslint-disable-next-line
  }, [slug, pageNumber]);

  const pageContent = page && <Fragment>
        <h1 className='title is-1'>{page.title}</h1>
        {page.content && (
          <div
            dangerouslySetInnerHTML={{ __html: page.content.html }}
          ></div>
        )}
      </Fragment>

  const display = () => {
    switch(pageType) {
      case PageType.HOME:
        return <CategoryIndex pageNumber={pageNumber} />
      case PageType.POST:
        return pageSection(<Post slug={slug} />)
      case PageType.CATEGORY:
        return page && pageSection(pageContent, <CategoryIndex category={page.category} pageNumber={pageNumber} />);
      case PageType.SINGLE:
        return page && pageSection(pageContent);
      default:
        return undefined;
    }
  }

  const pageSection = (includeIn: ReactElement | undefined, includeBelow: ReactElement | undefined = undefined) => {
    return <Fragment>
        <section className='page-section'>
          <div className='container'>
            <div className='columns'>
              <div className='column'>
                {includeIn}
              </div>
            </div>
          </div>
        </section>
        {includeBelow}
      </Fragment>
  }

  return (
    <Fragment>
      <MainNav active={slug} pages={pages} />
      {display()}
    </Fragment>
  );
}

const mapStateToProps = (state: IApplicationState, { pageType }:any):any => ({
    page: state.pages.current,
    pages: state.pages.pages,
    pageType: state.pages.current?.pageType || pageType,
});

const mapDispatchToProps = ({
  setCurrentPage,
  getPage,
  getPages
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);

import React, { useMemo } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './App.scss';
import { PageType } from './types/PageTypes';
import Page from './components/content/Page';

function App() {
  const { search: queryString } = useLocation();

  const pageNumber = useMemo(() => {
      const searchParams = new URLSearchParams(queryString);
      const pg = searchParams.get('page');
      return pg ? parseInt(pg) : 0;
    }, [queryString]);

  return (
    <Switch>
      <Route exact path='/'>
        <Page slug='home' pageType={PageType.HOME} pageNumber={pageNumber}/>
      </Route>
      <Route path='/post/:slug' render={
          (props) => <Page slug={props.match.params.slug} pageType={PageType.POST} pageNumber={pageNumber} />
        }
      />
      <Route path='/:slug' render={
          (props) => <Page slug={props.match.params.slug} pageNumber={pageNumber} />
        }
      />
    </Switch>
  );
}

export default App;

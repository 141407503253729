import React, { useEffect } from "react";
import { connect } from "react-redux";

import Post from "./Post";
import { IPostsState } from "../../types/PostTypes";
import { getPosts } from "../../actions/postActions";
import IApplicationState from "../../types/ApplicationState";
import { Link } from "react-router-dom";

interface IProps {
  category?: string;
  tag?: string;
  pageNumber?: number;
  postsState?: IPostsState;
  getPosts?: (category: string | undefined | void, pageNumber?: number | undefined) => void;
}

function CategoryIndex({
  category = "",
  tag = "",
  pageNumber = undefined,
  postsState = { posts: [], postCount: 0, pageSize: 0, loading: false },
  getPosts,
}: IProps) {
  const pgNum:number = pageNumber ? pageNumber as number: 0;

  const { posts, pageSize, postCount } = postsState;

  useEffect(() => {
    getPosts && getPosts(category, pageNumber);
    // eslint-disable-next-line
  }, [pageNumber]);

  const hasPrev = pgNum - 1 >= 0;
  const hasNext = pgNum + 1 <= Math.ceil(postCount / pageSize) - 1; // zero based index

  const scrollTop = () => {
    document.documentElement.scrollTop = 0
  }

  return (
    <section className='category-index'>
      <div className='container'>
        <div className='columns'>
          <div className='column'>
            {posts.length ? posts.map((p) => <Post post={p} key={p.id} />) : ''}
            <p className='has-text-centered'>
              {hasPrev ? <Link to={`./?page=${pgNum-1}`} onClick={scrollTop}>Prev</Link> : ''}
              {' | '}
              {hasNext ? <Link to={`./?page=${pgNum+1}`} onClick={scrollTop}>Next</Link> : ''}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state: IApplicationState): any => {
  return {
    postsState: state.posts,
  };
};

export default connect(mapStateToProps, { getPosts })(CategoryIndex);

import { Dispatch } from "redux";
import axios from "axios";

import config from "../config";
import { ActionTypes } from "../types/ActionTypes";
import { IPost } from "../types/PostTypes";

export const getPost = (slug:string) => async (dispatch: Dispatch) => {
    try {
      setLoading();

      const res:any = await axios.post(config.ROOT_URL, {
        query: `{
        post(where: {slug: "${slug}"}) {
          id
          title
          slug
          date
          tags
          content {
            html
          }
          photos {
            id
            lens
            dateTaken
            imageUrl
            image {
              id
              fileName
              url
            }
          }
          category
        }
      }`,
      });

      dispatch({
        type: ActionTypes.GET_POST,
        payload: res.data.data.post,
      });
    } catch (e: any) {
      dispatch({
        type: ActionTypes.POST_ERROR,
        payload: e.response,
      });
    }
  };

export const getPosts = (category?: string, pageNumber?: number) => async (dispatch: any) => {
  try {
    const pageSize = 3;
    const now = new Date();
    const cat = category || 'MAIN';
    const skip = pageNumber ? pageNumber * pageSize : 0

    const q = `{
      posts(
        orderBy: date_DESC,
        skip: ${skip},
        first: ${pageSize},
        stage: PUBLISHED,
        where: {
          date_lte: "${now.toISOString()}",
          category: ${cat}
        }
        ) {
          id,
          title,
          slug,
          date,
          tags,
          category,
          content {
              html
          },
          photos {
            id,
            lens,
            dateTaken,
            imageUrl,
            image {
              id,
              fileName,
              url
            }
          }
      },
      postsConnection (
        orderBy: date_DESC,
        stage: PUBLISHED,
        where: {
          date_lte: "${now.toISOString()}",
          category: ${cat}
        }
        ) {
        aggregate {
          count
        }
      }
    }`;

    setLoading();
    const res: any = await axios({
      method: "post",
      url: config.ROOT_URL,
      data: {
        query: q,
      },
    });

    dispatch({
      type: ActionTypes.GET_POSTS,
      payload: res.data.data.posts as IPost[],
    });
    dispatch({
      type: ActionTypes.GET_POST_PAGE_COUNT,
      payload: res.data.data.postsConnection.aggregate.count,
    });
  } catch (e: any) {
    dispatch({
      type: ActionTypes.POST_ERROR,
      payload: e.response,
    });
  }
};

export const setLoading = () => {
  return {
    type: ActionTypes.SET_LOADING,
  };
};

import React, { Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { IPost } from "../../types/PostTypes";

import { getPost } from '../../actions/postActions';
import IApplicationState from "../../types/ApplicationState";

interface IProps {
  post?: IPost;
  slug?: string;
  getPost?: (slug:string) => void;
}

function Post({ post, slug, getPost }: IProps) {
  useEffect(()=> {
    if(!post || post.slug !== slug) {
      getPost && getPost(slug as string);
    }
    //eslint-disable-next-line
  }, [post]);

  return (
    <Fragment>
      { post &&
          <article className='message is-primary post'>
            <div className='message-header'>
              <h3 className='title is-3'>
                <Link to={`/post/${post.slug}`}>
                  {post.title}
                </Link>
              </h3>
            </div>
            <div className='message-body'>
              <div className='post-image-container'>
                <img
                  src={post.photos[0].imageUrl || post.photos[0].image.url}
                  className='image is-3by2 post-image'
                  alt={post.title}
                />
              </div>
              {post.content &&
                <div dangerouslySetInnerHTML={{ __html: post.content.html }}></div>
              }
            </div>
          </article>
      }
    </Fragment>
  );
}

const mapStateToProps = (state: IApplicationState, ownProps: any): any => {
  const { post } = ownProps;
  return {
    post: post || state.posts.post,
  }
}

export default connect(mapStateToProps, { getPost })(Post);
